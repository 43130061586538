<template>
  <div>
    <form @submit.prevent="!cintillo ? insertCintillo() : updateCintillo()">
      <b-row>
        <b-col>
          <b-form-group label="Color" label-for="colour">
            <b-form-input
              type="color"
              id="colour"
              v-model="objetoCintillo.color"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Descripción" label-for="description">
            <b-form-input
              id="description"
              v-model="objetoCintillo.description"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end mt-3">
        <b-overlay
          :show="busy"
          rounded
          opacity="0.85"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary">{{
            !cintillo ? "Registrar" : "Actualizar"
          }}</b-button>
        </b-overlay>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    cintillo: Object,
  },
  data() {
    return {
      objetoCintillo: {
        description: null,
        color: "#000000",
        created_by: null,
      },
      busy: false,
    };
  },
  mounted() {
    if (this.cintillo) {
      this.objetoCintillo.color = this.cintillo.color;
      this.objetoCintillo.description = this.cintillo.description;
    } else {
      this.objetoCintillo.description = null;
    }
  },
  methods: {
    insertCintillo() {
      this.busy = true;
      axios
        .post("/api/cintillo/insert", {
          description: this.objetoCintillo.description,
          color: this.objetoCintillo.color,
          created_by: this.currentUser.user_id,
        })
        .then((response) => {
          this.busy = false;
          this.$emit("closeModal", false);
        });
    },
    updateCintillo() {
      this.busy = true;
      axios
        .put(`/api/cintillo/update/${this.cintillo.id}`, {
          description: this.objetoCintillo.description,
          color: this.objetoCintillo.color,
          created_by: this.currentUser.user_id,
        })
        .then((response) => {
          this.busy = false;
          this.$emit("closeModal", false);
        });
    },
  },
};
</script>

<style>
</style>
